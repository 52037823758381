import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/app/components/AmenitiesSlider/SliderButtons.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/app/components/RecentlyViewed/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/components/ClickableIcon/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/components/general/MobileNav/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/components/general/SearchSection/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/LIH-Frontend-Guest/components/HotelCard/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/html/LIH-Frontend-Guest/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/LIH-Frontend-Guest/node_modules/next/dist/client/image-component.js");
