"use client";
import Image from "next/image";
import React, { useEffect } from "react";

const SliderButtons = () => {
  const [containerRef, setContainerRef] = React.useState(null);
  useEffect(() => {
    setContainerRef(document.querySelector(".amenities-container"));
  }, [containerRef]);

  return (
    <div className="scroll-container">
      <button
        className="scroll-button"
        onClick={() => {
          if (containerRef) {
            containerRef.scrollBy({ left: -200 });
          }
        }}
      >
        {/* <FontAwesomeIcon icon={faAngleLeft} fontSize={12} /> */}
        <Image
          src={"/assets/icons/svgs/arrow-left.svg"}
          width={24}
          height={24}
          alt="arrow-left"
        />
      </button>
      <button
        className="scroll-button"
        onClick={() => {
          if (containerRef) {
            containerRef.scrollBy({ left: 200 });
          }
        }}
      >
        {/* <FontAwesomeIcon icon={faAngleRight} fontSize={12} /> */}
        <Image
          src={"/assets/icons/svgs/arrow-right.svg"}
          width={24}
          height={24}
          alt="arrow-left"
        />
      </button>
    </div>
  );
};

export default SliderButtons;
